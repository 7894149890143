(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.height();
  });

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  }
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return ( WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH );
  }
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  }


  // Variables
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $WINDOW
    .on('resize', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre2');
        });
      }
      else {
        $('.gallery-rotator-block-container.gallery-items-width-4 .gallery-rotator-image img').attr('src', function() {
          return $(this).data('pre4');
        });
      }
    })
    .trigger('resize');


  $BODY
    .on('yiiListViewUpdating', function(e, id) {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function(e, id, data) {
      $.mouseLoader(false);

      if (id == 'product-list')
        $WINDOW.scrollTo('#'.concat(id), {axis: 'y', duration: 150});
    });


  $DOCUMENT
    .ready(function() {
      // Main page banner rotator
      // ------------------------
      $('#main-rotator').slick($.extend({}, slickSettings, {
        centerMode: true,
        centerPadding: '0px',
        slide: '.main-rotator-slide-container',
        appendArrows: '#main-rotator-controls',
        draggable: true
      }));


      // Gallery rotator
      // ---------------
      $('.gallery-rotator-block-container .list-view-item').each(function() {
        var $rotator = $(this).find('.gallery-rotator'),
            $pager = !IS_LARGE_PORTRAIT_MOBILE_WIDTH() ? $(this).find('.gallery-rotator-pager > div') : null;

        $rotator.slick($.extend({}, slickSettings, {
          asNavFor: $pager,
          arrows: false,
          slide: '.gallery-rotator-image',
          draggable: true
        }));

        if( !IS_LARGE_PORTRAIT_MOBILE_WIDTH() )
          $pager.slick($.extend({}, slickSettings, {
            asNavFor: $rotator,
            centerMode: false,
            arrows: false,
            focusOnSelect: true,
            slide: '.gallery-rotator-pager-image',
            slidesToShow: 6,
            swipe: false
          }));
      });


      // Project gallery
      // ---------------
      var $projectRotator = $('.product-gallery-container .product-gallery');

      if( $projectRotator.length ) {
        var $projectRotatorPager = !IS_LARGE_PORTRAIT_MOBILE_WIDTH() ? $('.product-gallery-container .product-gallery-pager') : null;

        $projectRotator.slick($.extend({}, slickSettings, {
          fade: true,
          asNavFor: $projectRotatorPager,
          arrows: false,
          slide: '.product-gallery-image',
          draggable: true
        }));

        if( !IS_LARGE_PORTRAIT_MOBILE_WIDTH() )
          $projectRotatorPager.slick($.extend({}, slickSettings, {
            asNavFor: $projectRotator,
            centerMode: false,
            arrows: false,
            focusOnSelect: true,
            slide: '.product-gallery-pager-image',
            slidesToShow: 3,
            swipe: false,
            vertical: true
          }));
      }
    })

    .on('click.headerCallbackMessageLink', '.header .header-contacts .header-buttons .callback-message-button', function() {
      CALLBACKS.headerCallbackMessageButton();
    })

    .on('click.headerCallbackPhoneLink', '.header .header-contacts .header-buttons .callback-phone-button', function() {
      CALLBACKS.headerCallbackPhoneButton();
    })

    .on('click.footerCallbackPhoneLink', '.footer .footer-left-container .footer-buttons .button-style', function() {
      CALLBACKS.footerCallbackPhoneButton();
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {axis: 'y', duration: speed, offset: offset});
    })

    // Price groupped quick links
    // --------------------------
    .on('click.price-groupped-quick-links', '.price-groupped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substr($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Portfolio info price item
    // -------------------------
    .on('click.portfolio-info-price-item', '.portfolio-info-icons .price-item > span, .portfolio-info-icons .price-stuff > span + span', function(e) {
      e.preventDefault();

      var $container = $('#portfolio-info-price-notice');

      if( !$container.html() ) {
        $.ajax({
          type: 'POST',
          url: '/ajax/render_content/',
          data: {
            ajaxRender: 'portfolioPriceNotice'
          },
          beforeSend: function() { $.mouseLoader(true); },
          dataType: 'html'
        })
          .done(function(response) {
            $.mouseLoader(false);

            $container.html(response);
            $.fancybox.open($container);
          })
          .fail(function() {
            $.mouseLoader(false);
            alert('Во время загрузки данных возникла ошибка, попробуйте позже.');
          });
      }
      else
        $.fancybox.open($container);
    })

    // Hide after
    // ----------
    .on('click.jsHideAfter', '.js-hide-after', function(e) {
      e.preventDefault();

      $(this).remove();
    })

    // Show-hide block
    // ---------------
    .on('click.jsShowHide', '.js-show-hide > div:first-child > span', function(e) {
      e.preventDefault();

      var $self = $(this),
          $container = $self.closest('.js-show-hide');

      if( $container.hasClass('active') )
        $self.text($container.removeClass('active').data('text'));
      else
        $self.text($container.addClass('active').data('textActive'));
    });

  if( IS_DESKTOP_WIDTH() ) {
    $WINDOW
      .on('load', function() {
        // Fix header submenu popup position
        // ---------------------------------
        var $headerMenu = $('.header-menu-container > ul'),
            headerMenuWidth = $headerMenu.outerWidth();

        $headerMenu.children().each(function() {
          var $item = $(this),
              $submenu = $item.children('ul');

          if( $submenu.length ) {
            $submenu.css('max-width', headerMenuWidth);

            var position = headerMenuWidth - $submenu.outerWidth() - $item.position().left;

            if( position < 0 )
              $submenu.css('left', position);
          }
        });
      });
  }
  else {
    $DOCUMENT
      .ready(function() {
        // Header menu button
        // ------------------
        $MENU_CHECKBOX
          .on('change', function() {
            if( this.checked ) {
              $BODY.addClass('menu-is-opened');
            } else {
              $BODY.removeClass('menu-is-opened');
            }
          })
          .prop('checked', false)
          .prop('disabled', false)
          .trigger('change');
      })

      // Close header menu
      // -----------------
      .on('click.closeHeaderMenu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu-container > ul').length || $(e.target).closest('.header-city-choose-container').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.headerMenu', '.header-menu-container > ul li > div > div > a > span, .header-menu-container ul li > div > div > span > span, .header-menu-container ul li > div > .dropmarker', function(e) {
        e.preventDefault();

        var $self = $(this).parent(),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').slideUp(300);

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( $self.prop("tagName").toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-hovered').children('ul').slideUp(300);
          } else {
            $parent.addClass('is-hovered').children('ul').slideDown(300);
          }
        } else {
          if( $self.prop("tagName").toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });


  // Portfolio filter
  // ----------------
  if( $('.portfolio-page-block-container').length ) {
    var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items')
          /*.on('layoutComplete', function(e, items) {
            console.log('Isotope layout completed on ' + items.length + ' items');
          })*/
          .isotope({
            itemSelector: '.list-view-item',
            layoutMode: 'fitRows',
            percentPosition: true,
            transitionDuration: '0.5s',
            containerStyle: null
          }),
        $links = $('.portfolio-page-block-container .quick-link-list a');

    $links.on('click.portfolio-filter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="'.concat(filterValue, '"]')).addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: '[data-filter-section="'.concat(filterValue, '"]')});
        }
      }
    });
  }
})();